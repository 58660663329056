import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import reduceChildRoutes from "./reduceChildRoutes";

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const navigate = useNavigate();
  const currentRoute = router.pathname;
  const { auth } = useAuth();
  const pageActionNumber = auth.pageActionNumber;
  const pageActionNumberFormat = pageActionNumber.map(
    (x) => (depth === 0 ? x.substring(0, 4) : x.substring(0, 8)) //for page id if child else module id
  );
  const navbarItem = pages.filter((item) =>
    pageActionNumberFormat.includes(depth === 0 ? item.moduleId : item.pageId)
  );

  const childRoutes = navbarItem.reduce(
    (items, page) =>
      reduceChildRoutes({
        items,
        page,
        currentRoute,
        depth,
        pageActionNumber,
        navigate,
      }),
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
