import styled from "@emotion/styled";
import React from "react";
import { Outlet } from "react-router-dom";

import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import GuestGuard from "../components/guards/GuestGuard";

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Auth = ({ children }) => {
  return (
    <GuestGuard>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        {children}
        <Outlet />
      </Root>
    </GuestGuard>
  );
};

export default Auth;
