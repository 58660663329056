import styled from "@emotion/styled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NotificationsIcon from "@mui/icons-material/Notifications";

import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Avatar as MuiAvatar,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { Bell } from "react-feather";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxios";
import { NOTIFICATION_SERVICE } from "../../services/constants";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({
  title,
  Icon,
  isRead,
  createdDate,
  notificationId,
  type,
  typeId,
  setOpen,
  handleOpen,
  getNotificationCount,
}) {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  function convertToTimeAgo(createdDate) {
    const notificationDate = new Date(createdDate);
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - notificationDate.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);

    if (secondsDifference < 60) {
      return "just now";
    } else if (secondsDifference < 3600) {
      const minutes = Math.floor(secondsDifference / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (secondsDifference < 86400) {
      const hours = Math.floor(secondsDifference / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (secondsDifference < 2592000) {
      const days = Math.floor(secondsDifference / 86400);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (secondsDifference < 31536000) {
      const months = Math.floor(secondsDifference / 2592000);
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else {
      const years = Math.floor(secondsDifference / 31536000);
      return `${years} year${years > 1 ? "s" : ""} ago`;
    }
  }

  const handleNavigate = useCallback(
    (type, typeId) => () => {
      if (
        type === "CreateCateringInquiry" ||
        type === "EditCateringInquiry" ||
        type == "ReassignCateringInquiry"
      ) {
        setOpen(false);
        navigate("/catering/catering-inquiries/edit-inquiry", {
          state: { inquiryId: typeId },
        });
      } else if (
        type === "CreateCateringQuote" ||
        type === "EditCateringQuote" ||
        type == "ReassignCateringQuote"
      ) {
        setOpen(false);
        navigate("/catering/manage-quote", {
          state: { quoteId: typeId },
        });
      }
      //corporate
      if (
        type === "CreateCorporateInquiry" ||
        type === "EditCorporateInquiry" ||
        type == "ReassignCorporateInquiry"
      ) {
        setOpen(false);
        navigate("/corporate/manage-inquiry", {
          state: { inquiryId: typeId },
        });
      } else if (
        type === "CreateCorporateQuote" ||
        type === "EditCorporateQuote" ||
        type == "CorporateQuoteSigned" ||
        type == "CorporateVenueFacilityRental" ||
        type == "ReassignCorporateQuote"
      ) {
        setOpen(false);
        navigate("/corporate/quotes/manage-quote", {
          state: { quoteId: typeId },
        });
      }
      //social
      if (
        type === "CreateSocialInquiry" ||
        type === "EditSocialInquiry" ||
        type == "ReassignSocialInquiry"
      ) {
        setOpen(false);
        navigate("/social/quotes/manage-quote", {
          state: { inquiryId: typeId },
        });
      } else if (
        type === "CreateSocialQuote" ||
        type === "EditSocialQuote" ||
        type == "ReassignSocialQuote" ||
        type == "SocialQuoteSigned" ||
        type == "SocialVenueFacilityRental"
      ) {
        setOpen(false);
        navigate("/social/quotes/manage-quote", {
          state: { quoteId: typeId },
        });
      }
    },
    [navigate]
  );

  const handleReadNotification = async (type, typeId, notificationId) => {
    const url =
      NOTIFICATION_SERVICE +
      "ReadNotification?notificationId=" +
      notificationId;

    try {
      const response = await axios.get(url);
      console.log(response.data, "response");

      if (response.data.isSuccess) {
        getNotificationCount();
        if (
          type === "CreateCateringInquiry" ||
          type === "EditCateringInquiry" ||
          type == "ReassignCateringInquiry"
        ) {
          setOpen(false);
          navigate("/catering/catering-inquiries/edit-inquiry", {
            state: { inquiryId: typeId },
          });
        } else if (
          type === "CreateCateringQuote" ||
          type === "EditCateringQuote" ||
          type == "ReassignCateringQuote"
        ) {
          setOpen(false);
          navigate("/catering/manage-quote", {
            state: { quoteId: typeId },
          });
        }
        //corporate
        else if (
          type === "CreateCorporateInquiry" ||
          type === "EditCorporateInquiry" ||
          type == "ReassignCorporateInquiry"
        ) {
          setOpen(false);
          navigate("/corporate/manage-inquiry", {
            state: { inquiryId: typeId },
          });
        } else if (
          type === "CreateCorporateQuote" ||
          type === "EditCorporateQuote" ||
          type == "CorporateQuoteSigned" ||
          type == "CorporateVenueFacilityRental" ||
          type == "ReassignCorporateQuote"
        ) {
          setOpen(false);
          navigate("/corporate/quotes/manage-quote", {
            state: { quoteId: typeId },
          });
        }
        //social
        else if (
          type === "CreateSocialInquiry" ||
          type === "EditSocialInquiry" ||
          type == "ReassignSocialInquiry"
        ) {
          setOpen(false);
          navigate("/social/quotes/manage-quote", {
            state: { inquiryId: typeId },
          });
        } else if (
          type === "CreateSocialQuote" ||
          type === "EditSocialQuote" ||
          type == "ReassignSocialQuote" ||
          type == "SocialQuoteSigned" ||
          type == "SocialVenueFacilityRental"
        ) {
          setOpen(false);
          navigate("/social/quotes/manage-quote", {
            state: { quoteId: typeId },
          });
        }
      }
    } catch (error) {}
  };

  return (
    <ListItemButton
      sx={{
        py: 3,
        px: 4.5,
        mt: "1px",
        ...(!isRead && {
          bgcolor: "action.selected",
        }),
      }}
      // onClick={handleNavigate(type, typeId)}
      onClick={() => {
        handleReadNotification(type, typeId, notificationId);
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <AccessTimeIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
            {convertToTimeAgo(createdDate)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

function NavbarNotificationsDropdown() {
  const axios = useAxiosPrivate();
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const { auth } = useAuth();

  const getNotificationCount = async () => {
    if (auth.refreshToken) {
      const url = NOTIFICATION_SERVICE + "GetUnreadNotificationCount";
      try {
        const response = await axios.get(url);
        const output = response.data;
        if (!isNaN(output)) {
          setNotificationCount(output);
        }
      } catch (error) {}
    }
  };

  React.useEffect(() => {
    getNotificationCount();
    const interval = setInterval(() => {
      getNotificationCount();
    }, 60000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = async () => {
    const url = NOTIFICATION_SERVICE + "GetAllNotifications";
    try {
      const response = await axios.get(url);
      const output = response.data;

      setNotification(output);
      setOpen(true);
    } catch (error) {}
  };

  const handleClose = () => {
    setOpen(false);
  };
  // function getNotificationType(type) {
  //   switch (type) {
  //     case "CREATECAMPAIGN":
  //       return Assignment;
  //     case "CREATEFOREST":
  //       return ForestIcon;
  //     default:
  //       return PendingActions;
  //   }
  // }
  const [viewAllNotification, setViewAllNotification] = useState(false);
  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator
            badgeContent={
              notificationCount === 0 ? null : notificationCount.toString()
            }
          >
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: "500px",
          },
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        {notificationCount > 0 && (
          // <NotificationHeader p={2}>
          //   <Typography variant="subtitle1" color="textPrimary">
          //     {notificationCount.toString() + " New Notifications"}
          //   </Typography>
          // </NotificationHeader>
          <NotificationHeader>
            <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">Notifications</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  You have {notificationCount.toString()} unread messages
                </Typography>
              </Box>
            </Box>
          </NotificationHeader>
        )}
        <React.Fragment>
          <List disablePadding>
            {viewAllNotification ? (
              <>
                {notification &&
                  notification.map((value, index) => {
                    return (
                      <Notification
                        key={index}
                        title={value.message}
                        // Icon={getNotificationType(value.type)}
                        Icon={NotificationsIcon}
                        isRead={value.isRead}
                        createdDate={value.createdDate}
                        notificationId={value.notificationId}
                        type={value.type}
                        typeId={value.typeId}
                        setOpen={setOpen}
                        handleOpen={handleOpen}
                        getNotificationCount={getNotificationCount}
                      />
                    );
                  })}
              </>
            ) : (
              <>
                {notification &&
                  notification
                    .filter((value, index) => index < 5)
                    .map((value, index) => {
                      return (
                        <Notification
                          key={index}
                          title={value.message}
                          // Icon={getNotificationType(value.type)}
                          Icon={NotificationsIcon}
                          isRead={value.isRead}
                          createdDate={value.createdDate}
                          notificationId={value.notificationId}
                          type={value.type}
                          typeId={value.typeId}
                          setOpen={setOpen}
                          handleOpen={handleOpen}
                          getNotificationCount={getNotificationCount}
                        />
                      );
                    })}
              </>
            )}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            {viewAllNotification ? (
              <>
                <Button
                  fullWidth
                  size="small"
                  onClick={() => setViewAllNotification(false)}
                >
                  Show Less
                </Button>
              </>
            ) : (
              <>
                {notification.length === 0 ? (
                  <>
                    <Typography fontSize={15} color="primary" p={3}>
                      No Notifications
                    </Typography>
                  </>
                ) : notification.length > 3 ? (
                  <>
                    <Button
                      fullWidth
                      size="small"
                      onClick={() => setViewAllNotification(true)}
                    >
                      View all
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
