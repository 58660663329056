import styled from "@emotion/styled";
import React from "react";

import { Avatar, Grid, Typography } from "@mui/material";

import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooter = ({ ...rest }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  // function getOrganisationLogo() {
  //   if (user.organistationLogo) {
  //     return user.organistationLogo + "?${" + new Date() + "}";
  //   }
  //   return organisationLogo + "?${" + new Date() + "}";
  // }
  return (
    <Footer {...rest}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={2.5}>
          {!!auth && (
            <Avatar
              alt={auth.userName}
              src={auth.image ? auth.image + "?${" + new Date() : ""}
              onClick={() => {
                navigate("/profile");
              }}
            />
          )}
        </Grid>
        <Grid item xs={9.5}>
          {!!auth && (
            <>
              <FooterText
                variant="body2"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                {auth.userName}
              </FooterText>
              <FooterSubText variant="caption">
                {auth.roles} - {auth.team}
              </FooterSubText>
            </>
          )}
          {/* Demo data */}
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
