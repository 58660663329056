import {
  CalendarMonth,
  CorporateFare,
  CreditCard,
  Dashboard,
  EventAvailable,
  Festival,
  People,
  Person,
  RoomService,
  Settings,
} from "@mui/icons-material";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import CoffeeIcon from "@mui/icons-material/Coffee";
import DeleteIcon from "@mui/icons-material/Delete";
import HailIcon from "@mui/icons-material/Hail";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LiquorIcon from "@mui/icons-material/Liquor";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import ShieldIcon from "@mui/icons-material/Shield";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
const pagesSection = [
  {
    href: "/dashboard",
    icon: Dashboard,
    title: "Dashboard",
    moduleId: "0001",
  },
  {
    href: "/appointments",
    icon: EventAvailable,
    title: "My Calendar",
    moduleId: "0001",
  },
  // {
  //   href: "/my-communications",
  //   icon: Email,
  //   title: "My Communications",
  //   moduleId: "0002",
  // },
  {
    href: "/catering",
    icon: RoomService,
    title: "Catering",
    moduleId: "0003",
    children: [
      {
        href: "/catering/calendar",
        icon: CalendarMonth,
        title: "Order Calendar",
        pageId: "00030001",
      },
      {
        href: "/catering/inquiries",
        icon: SupportAgentIcon,
        title: "Inquiries",
        pageId: "00030002",
      },

      {
        href: "/catering/quotes",
        icon: HandshakeIcon,
        title: "Quotes",
        pageId: "00030003",
      },
      {
        href: "/catering/payments",
        icon: CreditCard,
        title: "Payments",
        pageId: "00030004",
      },
      {
        href: "/catering/events",
        icon: Festival,
        title: "Events",
        pageId: "00030005",
      },
    ],
  },
  {
    href: "/corporate",
    icon: CorporateFare,
    title: "Corporate",
    moduleId: "0004",
    children: [
      {
        href: "/corporate/calendar",
        icon: CalendarMonth,
        title: "Event Calendar",
        pageId: "00040001",
      },
      {
        href: "/corporate/inquiries",
        icon: SupportAgentIcon,
        title: "Inquiries",
        pageId: "00040001",
      },
      {
        href: "/corporate/quotes",
        icon: HandshakeIcon,
        title: "Quotes",
        pageId: "00040002",
      },
      {
        href: "/corporate/events",
        icon: Festival,
        title: "Events",
        pageId: "00040003",
      },
      {
        href: "/corporate/payments",
        icon: CreditCard,
        title: "Payments",
        pageId: "00040004",
      },
    ],
  },
  {
    href: "/social",
    icon: People,
    title: "Social",
    moduleId: "0005",
    children: [
      {
        href: "/social/calendar",
        icon: CalendarMonth,
        title: "Booking Calendar",
        pageId: "00050001",
      },
      {
        href: "/social/inquiries",
        icon: SupportAgentIcon,
        title: "Inquiries",
        pageId: "00050001",
      },
      {
        href: "/social/quotes",
        icon: HandshakeIcon,
        title: "Quotes",
        pageId: "00050002",
      },
      {
        href: "/social/events",
        icon: Festival,
        title: "Events",
        pageId: "00050003",
      },
      {
        href: "/social/payments",
        icon: CreditCard,
        title: "Payments",
        pageId: "00050004",
      },
    ],
  },
  {
    href: "/customer-management",
    icon: Person,
    title: "Customer Management",
    moduleId: "0009",
  },
  {
    href: "/user-management",
    icon: Person,
    title: "User Management",
    moduleId: "0008",
  },
  // {
  //   href: "/reports",
  //   icon: Summarize,
  //   title: "Reports",
  //   moduleId: "0003",

  //   children: [
  //     {
  //       href: "/reports/outstanding",
  //       icon: CurrencyExchange,
  //       title: "Revenue Reports",
  //       pageId: "00030002",
  //     },
  //     {
  //       href: "/reports/benchmarks",
  //       icon: Analytics,
  //       title: "Analysis and Benchmark",
  //       pageId: "00030002",
  //     },
  //     {
  //       href: "/reports/comparison",
  //       icon: Compare,
  //       title: "Team Comparison",
  //       pageId: "00030002",
  //     },
  //   ],
  // },

  {
    href: "/settings",
    icon: Settings,
    title: "Management",
    moduleId: "0006",
    children: [
      {
        href: "/settings/menu-management",
        icon: RoomService,
        title: "Menu",
        pageId: "00060001",
      },
      {
        href: "/settings/bar-management",
        icon: LiquorIcon,
        title: "Bar Menu",
        pageId: "00060002",
      },
      // {
      //   href: "/settings/bar-condiment",
      //   icon: SportsBarIcon,
      //   title: "Bar condiments",
      //   pageId: "00060003",
      // },

      {
        href: "/settings/cold-beverages",
        icon: LocalBarIcon,
        title: "Cold Beverages",
        pageId: "00060005",
      },
      {
        href: "/settings/hot-beverages",
        icon: CoffeeIcon,
        title: "Hot Beverages",
        pageId: "00060004",
      },

      {
        href: "/settings/rentals-management",
        icon: ChairAltIcon,
        title: "Rentals",
        pageId: "00060006",
      },
      {
        href: "/settings/disposables-management",
        icon: DeleteIcon,
        title: "Disposables",
        pageId: "00060007",
      },
      {
        href: "/settings/decor-management",
        icon: LocalFloristIcon,
        title: "Decor",
        pageId: "00060008",
      },
      {
        href: "/settings/security-management",
        icon: ShieldIcon,
        title: "Security",
        pageId: "00060009",
      },
      {
        href: "/settings/vendor",
        icon: HailIcon,
        title: "Vendor",
        pageId: "00060010",
      },
      {
        href: "/settings/venue-management",
        icon: CorporateFare,
        title: "Venue",
        pageId: "00060011",
      },
    ],
  },

  // {
  //   href: "/reports/under-construction",
  //   icon: Summarize,
  //   title: "Reports",
  //   moduleId: "0007",
  // },
];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
