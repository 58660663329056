import styled from "@emotion/styled";
import React from "react";

import { Alert, Grid, Paper, Typography } from "@mui/material";

import { useState } from "react";
import ResetPasswordComponent from "../../components/auth/ResetPassword";
import { ReactComponent as Logo } from "../../vendor/logo.svg";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword() {
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <React.Fragment>
      {/* <Brand /> */}
      <img src="/static/img/logo.png" alt="" style={{ width: "76%" }}></img>
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>

        {isSuccess ? (
          <>
            <Grid container mt={4}>
              <Alert mt={4} mb={1} severity="success">
                We have sent you an email with the link to reset your password.
              </Alert>
            </Grid>
          </>
        ) : (
          <>
            <Typography component="h2" variant="body1" align="center">
              Enter your email to reset your password
            </Typography>
            <ResetPasswordComponent setSuccess={setIsSuccess} />
          </>
        )}
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
