import { useNavigate } from "react-router-dom";
import { axiosBase } from "../services";
import { AUTH_SERVICE } from "../services/constants";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const refresh = async () => {
    const REFRESH_URL = AUTH_SERVICE + "RefreshToken";
    const response = await axiosBase.post(REFRESH_URL, {
      authToken: auth.refreshToken,
    });
    let newRefreshToken = null;
    try {
      if (!response.data.success || response.data.isReAuthRequired) {
        throw new Error("Log out");
      }
      newRefreshToken = response.data.token;

      setAuth((prev) => {
        return { ...prev, refreshToken: response.data.token };
      });
      const roles = auth.roles;
      const userId = auth.userId;
      const userName = auth.userName;
      const image = auth.image;
      const team = auth.team;
      const pageActionNumber = auth.pageActionNumber;
      const refreshToken = newRefreshToken;
      localStorage.setItem(
        "auth",
        JSON.stringify({
          roles,
          refreshToken,
          userId,
          userName,
          pageActionNumber,
          image,
          team,
        })
      );
    } catch {
      setAuth({
        roles: null,
        refreshToken: null,
        userId: null,
        userName: null,
        pageActionNumber: null,
        image: null,
        team: null,
      });
      localStorage.removeItem("auth");
      navigate("/");
    }

    return newRefreshToken;
  };
  return refresh;
};

export default useRefreshToken;
