import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import Page404Layout from "./layouts/Page404";
// Guards
// Auth components
import PageGuard from "./components/guards/PageRestriction";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Page404 from "./pages/auth/Page404";
import ResetPassword from "./pages/auth/ResetPassword";
import SignIn from "./pages/auth/SignIn";

const Appointments = async(() => import("./pages/appointments"));

const Communications = async(() => import("./pages/communications"));
const Customers = async(() => import("./pages/customers"));
const UserManagement = async(() => import("./pages/usermanagement"));
const CreateUser = async(() => import("./pages/usermanagement/userManage"));
const EditUser = async(() =>
  import("./pages/usermanagement/EditUser/userManage")
);

//catering inquiries
const CateringInquiries = async(() =>
  import("./pages/inquiries/catering/inquiry")
);
const CateringCalendar = async(() => import("./pages/catering/calendar"));
const CateringPayments = async(() => import("./pages/catering/payments"));
const CorporatePayments = async(() => import("./pages/corporate/payments"));
const CorporateCalendar = async(() =>
  import("./pages/corporate/corporateCalendar")
);
const SocialCalendar = async(() => import("./pages/social/socialCalendar"));
const SocialPayments = async(() => import("./pages/social/payments"));

const ManageCateringPayments = async(() =>
  import("./pages/catering/payments/managePayments")
);
const ManageCorporatePayments = async(() =>
  import("./pages/corporate/payments/managePayments")
);
const ManageSocialPayments = async(() =>
  import("./pages/social/payments/managePayments")
);

const NewCateringInquiries = async(() =>
  import("./pages/inquiries/catering/new-inquiry")
);

const EditCateringInquiries = async(() =>
  import("./pages/inquiries/catering/manage-inquiry")
);
//social inquiries
const SocialInquiries = async(() => import("./pages/inquiries/social/inquiry"));
const NewSocialInquiries = async(() =>
  import("./pages/inquiries/social/new-inquiry")
);
const ManageSocialInquiries = async(() =>
  import("./pages/inquiries/social/manage-inquiry")
);
//corporate inquiries
const CorporateInquiries = async(() =>
  import("./pages/inquiries/corporate/inquiry")
);

const NewCorporateInquiries = async(() =>
  import("./pages/inquiries/corporate/new-inquiry")
);
const ManageCorporateInquiries = async(() =>
  import("./pages/inquiries/corporate/manage-inquiry")
);
//catering quotes
const CateringQuotes = async(() => import("./pages/quotes/catering/quotes"));
const NewCateringQuotes = async(() =>
  import("./pages/quotes/catering/new-qoute")
);

const ManageCateringQuote = async(() =>
  import("./pages/quotes/catering/manageQuote")
);

const SummaryCatering = async(() =>
  import("./pages/quotes/catering/quotes/summary")
);

//corporate quotes
const CorporateQuotes = async(() => import("./pages/quotes/corporate/quotes"));
const NewCorporateQuotes = async(() =>
  import("./pages/quotes/corporate/new-qoute")
);
const ManageCorporateQuote = async(() =>
  import("./pages/quotes/corporate/manage-quote")
);
const ViewCorporateQuote = async(() =>
  import("./pages/quotes/corporate/quotes/view-qoute")
);

const SendCorporateContract = async(() =>
  import("./pages/quotes/corporate/quotes/sendContract")
);

const Events = async(() => import("./pages/corporate/events-corporate"));
const EventDetails = async(() =>
  import("./pages/corporate/events-corporate/eventDetails")
);
//social quote

const SocialQuotes = async(() => import("./pages/quotes/social/quotes"));
const NewSocialQuotes = async(() => import("./pages/quotes/social/new-qoute"));
const ManageSocialQuote = async(() =>
  import("./pages/quotes/social/manage-quote")
);
const ViewSocialQuote = async(() =>
  import("./pages/quotes/social/quotes/view-qoute")
);

const SendSocialContract = async(() =>
  import("./pages/quotes/social/quotes/sendContract")
);

const SocialEvents = async(() => import("./pages/social/events-corporate"));
const SocialEventDetails = async(() =>
  import("./pages/social/events-corporate/eventDetails")
);
const CateringEvents = async(() => import("./pages/catering/events-corporate"));
const CateringEventDetails = async(() =>
  import("./pages/catering/events-corporate/eventDetails")
);
//settings
const MenuManagement = async(() => import("./pages/settings/menu-management"));
const CreateMenuManagement = async(() =>
  import("./pages/settings/menu-management/menu-management-section")
);
const EditMenuManagement = async(() =>
  import("./pages/settings/menu-management/EditMenu/menuManagement")
);
const ViewMenuManagement = async(() =>
  import("./pages/settings/menu-management/ViewMenu/menuManagement")
);
const BarManagement = async(() => import("./pages/settings/bar-management"));
const CreateBar = async(() =>
  import("./pages/settings/bar-management/barManagement")
);
const EditBarManagement = async(() =>
  import("./pages/settings/bar-management/EditBar/barManagement")
);
const ViewBarManagement = async(() =>
  import("./pages/settings/bar-management/viewBar/barManagement")
);
const BarCondimentManagement = async(() =>
  import("./pages/settings/bar-management/bar-condiment")
);
const CreateBarCondiment = async(() =>
  import("./pages/settings/bar-management/bar-condiment/barManagement")
);
const EditBarCondiment = async(() =>
  import(
    "./pages/settings/bar-management/bar-condiment/EditbarCondiment/barManagement"
  )
);
const ViewBarCondiment = async(() =>
  import(
    "./pages/settings/bar-management/bar-condiment/ViewBarCondiment/barManagement"
  )
);
const VendorManagement = async(() =>
  import("./pages/settings/vendor-management")
);
const CreateVendor = async(() =>
  import("./pages/settings/vendor-management/barManagement")
);
const EditVendor = async(() =>
  import("./pages/settings/vendor-management/editVendor/barManagement")
);
const ViewVendor = async(() =>
  import("./pages/settings/vendor-management/ViewVendor/barManagement")
);

const HotBeveragesManagement = async(() =>
  import("./pages/settings/beverages-management/hotBeverages")
);
const CreateHotBeverages = async(() =>
  import("./pages/settings/beverages-management/hotBeverages/barManagement")
);
const EditHotBeverages = async(() =>
  import(
    "./pages/settings/beverages-management/hotBeverages/editHotBeverages/barManagement"
  )
);
const ViewHotBeverages = async(() =>
  import(
    "./pages/settings/beverages-management/hotBeverages/viewHotBeverages/barManagement"
  )
);

const ColdBeveragesManagement = async(() =>
  import("./pages/settings/beverages-management/coldBeverages")
);
const CreateColdBeverages = async(() =>
  import("./pages/settings/beverages-management/coldBeverages/barManagement")
);
const EditColdBeverages = async(() =>
  import(
    "./pages/settings/beverages-management/coldBeverages/editColdBeverages/barManagement"
  )
);
const ViewColdBeverages = async(() =>
  import(
    "./pages/settings/beverages-management/coldBeverages/viewColdBeverages/barManagement"
  )
);

const RentalsManagement = async(() =>
  import("./pages/settings/rentalManagement")
);
const CreateRentals = async(() =>
  import("./pages/settings/rentalManagement/rentalsManagement")
);
const EditRentals = async(() =>
  import("./pages/settings/rentalManagement/EditRentals/rentalsManagement")
);

const ViewRentals = async(() =>
  import("./pages/settings/rentalManagement/ViewRentals/rentalsManagement")
);

const DisposablesManagement = async(() =>
  import("./pages/settings/disposables-management")
);
const CreateDisposables = async(() =>
  import("./pages/settings/disposables-management/disposablesManagement")
);
const EditDisposables = async(() =>
  import(
    "./pages/settings/disposables-management/EditDisposables/disposablesManagement"
  )
);
const ViewDisposables = async(() =>
  import(
    "./pages/settings/disposables-management/ViewDisposables/disposablesManagement"
  )
);

const DecorManagement = async(() =>
  import("./pages/settings/decor-management")
);
const CreateDecor = async(() =>
  import("./pages/settings/decor-management/decorManagement")
);
const EditDecor = async(() =>
  import("./pages/settings/decor-management/EditDecor/decorManagement")
);
const ViewDecor = async(() =>
  import("./pages/settings/decor-management/ViewDecor/decorManagement")
);

const SecurityManagement = async(() =>
  import("./pages/settings/secirity-management")
);
const CreateSecurity = async(() =>
  import("./pages/settings/secirity-management/securityManagement")
);
const EditSecurity = async(() =>
  import("./pages/settings/secirity-management/EditSecurity/securityManagement")
);
const ViewSecurity = async(() =>
  import("./pages/settings/secirity-management/ViewSecurity/securityManagement")
);
const Venue = async(() => import("./pages/venues"));
const ManageVenue = async(() => import("./pages/venues/manage-venue"));
const ViewVenue = async(() => import("./pages/venues/view-venue"));

const EditProfile = async(() => import("./pages/profileview/editUser"));
const ProfileView = async(() => import("./pages/profileview/profile"));
// const ManageVenue = async(() => import("./pages/venues/manage-venue/index"));
const RevenueReport = async(() =>
  import("./pages/reports/catering/outstandingrevenuereport")
);

const BenchMarkReport = async(() =>
  import("./pages/reports/catering/benchmark")
);

const ComparisonReport = async(() =>
  import("./pages/reports/catering/comparison")
);

const DashboardReport = async(() => import("./pages/dashboard"));

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn pageNumber="" />,
      },
      {
        path: "reset-password",
        element: <ResetPassword pageNumber="" />,
      },
      {
        path: "forgot-password/:id",
        element: <ForgotPassword pageNumber="" />,
      },
    ],
  },
  {
    path: "reports",
    element: <DashboardLayout />,
    children: [
      {
        path: "outstanding",
        element: (
          <PageGuard>
            <RevenueReport pageNumber="00030002" />
          </PageGuard>
        ),
      },
      {
        path: "benchmarks",
        element: (
          <PageGuard>
            <BenchMarkReport pageNumber="00030002" />
          </PageGuard>
        ),
      },
      {
        path: "comparison",
        element: (
          <PageGuard>
            <ComparisonReport pageNumber="00030002" />
          </PageGuard>
        ),
      },
    ],
  },
  {
    path: "catering",
    element: <DashboardLayout />,
    children: [
      {
        path: "calendar",
        element: (
          <PageGuard>
            <CateringCalendar pageNumber="00030001" />
          </PageGuard>
        ),
      },
      {
        path: "payments",
        element: (
          <PageGuard>
            <CateringPayments pageNumber="00030004" />,
          </PageGuard>
        ),
      },
      {
        path: "payments/manage-payments",
        element: (
          <PageGuard>
            <ManageCateringPayments pageNumber="00030004" />
          </PageGuard>
        ),
      },
      {
        path: "inquiries",
        element: (
          <PageGuard>
            <CateringInquiries pageNumber="00030002" />
          </PageGuard>
        ),
      },
      {
        path: "inquiries/new-inquiry",
        element: (
          <PageGuard>
            <NewCateringInquiries pageNumber="00030002" />
          </PageGuard>
        ),
      },

      {
        path: "quotes",
        element: (
          <PageGuard>
            <CateringQuotes pageNumber="00030003" />
          </PageGuard>
        ),
      },
      {
        path: "new-quote",
        element: (
          <PageGuard>
            <NewCateringQuotes pageNumber="00030003" />
          </PageGuard>
        ),
      },
      {
        path: "manage-quote",
        element: (
          <PageGuard>
            <ManageCateringQuote pageNumber="00030003" />
          </PageGuard>
        ),
      },
      {
        path: "summary",
        element: (
          <PageGuard>
            <SummaryCatering pageNumber="00030003" />
          </PageGuard>
        ),
      },

      {
        path: "catering-inquiries/edit-inquiry",
        element: (
          <PageGuard>
            <EditCateringInquiries pageNumber="00030002" />
          </PageGuard>
        ),
      },
      {
        path: "events",
        element: (
          <PageGuard>
            <CateringEvents pageNumber="00030003" />
          </PageGuard>
        ),
      },
      {
        path: "events/event-details",
        element: (
          <PageGuard>
            <CateringEventDetails pageNumber="00030003" />
          </PageGuard>
        ),
      },
    ],
  },
  //corportate
  {
    path: "corporate",
    element: <DashboardLayout />,
    children: [
      {
        path: "calendar",
        element: (
          <PageGuard>
            <CorporateCalendar pageNumber="00040001" />
          </PageGuard>
        ),
      },
      {
        path: "inquiries",
        element: (
          <PageGuard>
            <CorporateInquiries pageNumber="00040001" />
          </PageGuard>
        ),
      },
      {
        path: "new-inquiry",
        element: (
          <PageGuard>
            <NewCorporateInquiries pageNumber="00040001" />
          </PageGuard>
        ),
      },
      {
        path: "manage-inquiry",
        element: (
          <PageGuard>
            <ManageCorporateInquiries pageNumber="00040001" />
          </PageGuard>
        ),
      },
      {
        path: "quotes",
        element: (
          <PageGuard>
            <CorporateQuotes pageNumber="00040002" />
          </PageGuard>
        ),
      },
      {
        path: "payments",
        element: (
          <PageGuard>
            <CorporatePayments pageNumber="00040004" />
          </PageGuard>
        ),
      },
      {
        path: "payments/manage-payments",
        element: (
          <PageGuard>
            <ManageCorporatePayments pageNumber="00040004" />
          </PageGuard>
        ),
      },
      {
        path: "quotes/new-quote",
        element: (
          <PageGuard>
            <NewCorporateQuotes pageNumber="00040002" />
          </PageGuard>
        ),
      },
      {
        path: "quotes/manage-quote",
        element: (
          <PageGuard>
            <ManageCorporateQuote pageNumber="00040002" />
          </PageGuard>
        ),
      },
      {
        path: "view-quote",
        element: (
          <PageGuard>
            <ViewCorporateQuote pageNumber="00040002" />
          </PageGuard>
        ),
      },
      {
        path: "send-proposal",
        element: (
          <PageGuard>
            <SendCorporateContract pageNumber="00040002" />
          </PageGuard>
        ),
      },
      {
        path: "events",
        element: (
          <PageGuard>
            <Events pageNumber="00040003" />
          </PageGuard>
        ),
      },
      {
        path: "event-details",
        element: (
          <PageGuard>
            <EventDetails pageNumber="00040003" />
          </PageGuard>
        ),
      },
    ],
  },
  //social

  {
    path: "social",
    element: <DashboardLayout />,
    children: [
      {
        path: "calendar",
        element: (
          <PageGuard>
            <SocialCalendar pageNumber="00050001" />,
          </PageGuard>
        ),
      },
      {
        path: "inquiries",
        element: (
          <PageGuard>
            <SocialInquiries pageNumber="00050001" />,
          </PageGuard>
        ),
      },
      {
        path: "inquiries/new-inquiry",
        element: (
          <PageGuard>
            <NewSocialInquiries pageNumber="00050001" />,
          </PageGuard>
        ),
      },
      {
        path: "inquiries/manage-inquiry",
        element: (
          <PageGuard>
            <ManageSocialInquiries pageNumber="00050001" />
          </PageGuard>
        ),
      },
      {
        path: "quotes",
        element: (
          <PageGuard>
            <SocialQuotes pageNumber="00050002" />
          </PageGuard>
        ),
      },
      {
        path: "payments",
        element: (
          <PageGuard>
            <SocialPayments pageNumber="00050004" />
          </PageGuard>
        ),
      },
      {
        path: "payments/manage-payments",
        element: (
          <PageGuard>
            <ManageSocialPayments pageNumber="00050004" />
          </PageGuard>
        ),
      },
      {
        path: "quotes/new-quote",
        element: (
          <PageGuard>
            <NewSocialQuotes pageNumber="00050002" />
          </PageGuard>
        ),
      },
      {
        path: "quotes/manage-quote",
        element: (
          <PageGuard>
            <ManageSocialQuote pageNumber="00050002" />
          </PageGuard>
        ),
      },
      {
        path: "quotes/view-quotes",
        element: (
          <PageGuard>
            <ViewSocialQuote pageNumber="00050002" />
          </PageGuard>
        ),
      },
      {
        path: "group-space-agreements/send-group-space-agreements",
        element: (
          <PageGuard>
            <SendSocialContract pageNumber="00050002" />
          </PageGuard>
        ),
      },
      {
        path: "events",
        element: (
          <PageGuard>
            <SocialEvents pageNumber="00050003" />
          </PageGuard>
        ),
      },
      {
        path: "events/event-details",
        element: (
          <PageGuard>
            <SocialEventDetails pageNumber="00050003" />
          </PageGuard>
        ),
      },
    ],
  },
  {
    path: "customer-management",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <PageGuard>
            <Customers pageNumber="00090001" />
          </PageGuard>
        ),
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <PageGuard>
            <DashboardReport pageNumber="00010001" />
          </PageGuard>
        ),
      },
    ],
  },
  {
    path: "user-management",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <PageGuard>
            <UserManagement pageNumber="00080001" />
          </PageGuard>
        ),
      },
      {
        path: "create-user",
        element: (
          <PageGuard>
            <CreateUser pageNumber="00080001" />
          </PageGuard>
        ),
      },
      {
        path: "edit-user",
        element: (
          <PageGuard>
            <EditUser pageNumber="00080001" />
          </PageGuard>
        ),
      },
    ],
  },

  {
    path: "my-communications",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <PageGuard>
            <Communications pageNumber="00020001" />
          </PageGuard>
        ),
      },
    ],
  },
  {
    path: "appointments",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <PageGuard>
            <Appointments pageNumber="00010001" />
          </PageGuard>
        ),
      },
    ],
  },
  {
    path: "profile",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProfileView pageNumber="" />,
      },
      {
        path: "edit",
        element: <EditProfile pageNumber="" />,
      },
    ],
  },
  {
    path: "settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "menu-management",
        element: (
          <PageGuard>
            <MenuManagement pageNumber="00060001" />
          </PageGuard>
        ),
      },
      {
        path: "create-menu",
        element: (
          <PageGuard>
            <CreateMenuManagement pageNumber="00060001" />
          </PageGuard>
        ),
      },
      {
        path: "edit-menu",
        element: (
          <PageGuard>
            <EditMenuManagement pageNumber="00060001" />
          </PageGuard>
        ),
      },
      {
        path: "view-menu",
        element: (
          <PageGuard>
            <ViewMenuManagement pageNumber="00060001" />
          </PageGuard>
        ),
      },
      {
        path: "bar-management",
        element: (
          <PageGuard>
            <BarManagement pageNumber="00060002" />
          </PageGuard>
        ),
      },
      {
        path: "create-bar",
        element: (
          <PageGuard>
            <CreateBar pageNumber="00060002" />
          </PageGuard>
        ),
      },
      {
        path: "edit-bar",
        element: (
          <PageGuard>
            <EditBarManagement pageNumber="00060002" />
          </PageGuard>
        ),
      },
      {
        path: "view-bar",
        element: (
          <PageGuard>
            <ViewBarManagement pageNumber="00060002" />
          </PageGuard>
        ),
      },

      {
        path: "bar-condiment",
        element: (
          <PageGuard>
            <BarCondimentManagement pageNumber="00060003" />
          </PageGuard>
        ),
      },
      {
        path: "create-bar-condiment",
        element: (
          <PageGuard>
            <CreateBarCondiment pageNumber="00060003" />
          </PageGuard>
        ),
      },
      {
        path: "edit-bar-condiment",
        element: (
          <PageGuard>
            <EditBarCondiment pageNumber="00060003" />
          </PageGuard>
        ),
      },
      {
        path: "view-bar-condiment",
        element: (
          <PageGuard>
            <ViewBarCondiment pageNumber="00060003" />
          </PageGuard>
        ),
      },
      {
        path: "vendor",
        element: (
          <PageGuard>
            <VendorManagement pageNumber="00060010" />
          </PageGuard>
        ),
      },
      {
        path: "create-vendor",
        element: (
          <PageGuard>
            <CreateVendor pageNumber="00060010" />
          </PageGuard>
        ),
      },
      {
        path: "edit-vendor",
        element: (
          <PageGuard>
            <EditVendor pageNumber="00060010" />
          </PageGuard>
        ),
      },
      {
        path: "view-vendor",
        element: (
          <PageGuard>
            <ViewVendor pageNumber="00060010" />
          </PageGuard>
        ),
      },
      {
        path: "hot-beverages",
        element: (
          <PageGuard>
            <HotBeveragesManagement pageNumber="00060004" />
          </PageGuard>
        ),
      },
      {
        path: "create-hot-beverages",
        element: (
          <PageGuard>
            <CreateHotBeverages pageNumber="00060004" />
          </PageGuard>
        ),
      },
      {
        path: "edit-hot-beverages",
        element: (
          <PageGuard>
            <EditHotBeverages pageNumber="00060004" />
          </PageGuard>
        ),
      },
      {
        path: "view-hot-beverages",
        element: (
          <PageGuard>
            <ViewHotBeverages pageNumber="00060004" />
          </PageGuard>
        ),
      },
      {
        path: "cold-beverages",
        element: (
          <PageGuard>
            <ColdBeveragesManagement pageNumber="00060005" />
          </PageGuard>
        ),
      },
      {
        path: "create-cold-beverages",
        element: (
          <PageGuard>
            <CreateColdBeverages pageNumber="00060005" />
          </PageGuard>
        ),
      },
      {
        path: "edit-cold-beverages",

        element: (
          <PageGuard>
            <EditColdBeverages pageNumber="00060005" />
          </PageGuard>
        ),
      },
      {
        path: "view-cold-beverages",
        element: (
          <PageGuard>
            <ViewColdBeverages pageNumber="00060005" />
          </PageGuard>
        ),
      },
      {
        path: "rentals-management",
        element: (
          <PageGuard>
            <RentalsManagement pageNumber="00060006" />
          </PageGuard>
        ),
      },
      {
        path: "create-rental",
        element: (
          <PageGuard>
            <CreateRentals pageNumber="00060006" />
          </PageGuard>
        ),
      },
      {
        path: "edit-rental",
        element: (
          <PageGuard>
            <EditRentals pageNumber="00060006" />
          </PageGuard>
        ),
      },
      {
        path: "view-rental",
        element: (
          <PageGuard>
            <ViewRentals pageNumber="00060006" />
          </PageGuard>
        ),
      },

      {
        path: "disposables-management",
        element: (
          <PageGuard>
            <DisposablesManagement pageNumber="00060007" />
          </PageGuard>
        ),
      },
      {
        path: "create-disposables",
        element: (
          <PageGuard>
            <CreateDisposables pageNumber="00060007" />
          </PageGuard>
        ),
      },
      {
        path: "edit-disposables",
        element: (
          <PageGuard>
            <EditDisposables pageNumber="00060007" />
          </PageGuard>
        ),
      },
      {
        path: "view-disposables",
        element: (
          <PageGuard>
            <ViewDisposables pageNumber="00060007" />
          </PageGuard>
        ),
      },

      {
        path: "decor-management",
        element: (
          <PageGuard>
            <DecorManagement pageNumber="00060008" />
          </PageGuard>
        ),
      },
      {
        path: "create-decor",
        element: (
          <PageGuard>
            <CreateDecor pageNumber="00060008" />
          </PageGuard>
        ),
      },
      {
        path: "edit-decor",
        element: (
          <PageGuard>
            <EditDecor pageNumber="00060008" />
          </PageGuard>
        ),
      },
      {
        path: "view-decor",
        element: (
          <PageGuard>
            <ViewDecor pageNumber="00060008" />
          </PageGuard>
        ),
      },

      {
        path: "security-management",
        element: (
          <PageGuard>
            <SecurityManagement pageNumber="00060009" />
          </PageGuard>
        ),
      },
      {
        path: "create-security",
        element: (
          <PageGuard>
            <CreateSecurity pageNumber="00060009" />
          </PageGuard>
        ),
      },
      {
        path: "edit-security",
        element: (
          <PageGuard>
            <EditSecurity pageNumber="00060009" />
          </PageGuard>
        ),
      },
      {
        path: "view-security",
        element: (
          <PageGuard>
            <ViewSecurity pageNumber="00060009" />
          </PageGuard>
        ),
      },
      {
        path: "venue-management",
        element: (
          <PageGuard>
            <Venue pageNumber="00060011" />
          </PageGuard>
        ),
      },
      {
        path: "manage-venue",
        element: (
          <PageGuard>
            <ManageVenue pageNumber="00060011" />
          </PageGuard>
        ),
      },
      {
        path: "view-venue",
        element: (
          <PageGuard>
            <ViewVenue pageNumber="00060011" />
          </PageGuard>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Page404Layout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
