export function addDaystoDate(date, noofDays) {
  return new Date().setDate(date.getDate() + noofDays);
}
export function convertDate(date, type) {
  if (!date) return " ";

  try {
    const dateNew = new Date(date);
    if (dateNew === undefined) return undefined;

    let month = dateNew.getMonth() + 1;
    if (month < 10) month = "0" + month;

    let day = dateNew.getDate();
    if (day < 10) day = "0" + day;

    let retDate = day + "/" + month + "/" + dateNew.getFullYear();

    if (type === "1") {
      retDate = day + "/" + month + "/" + dateNew.getFullYear();
    }
    return retDate;
  } catch {
    return " ";
  }
}
export function convertTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  let formattedHours = hours % 12;
  formattedHours = formattedHours ? formattedHours : 12; // handle midnight (0:00)

  return `${formattedHours}:${minutes}:${seconds} ${ampm}`;
}

export function formatCurrency(number) {
  let retVal = "";
  if (isNaN(number)) {
    try {
      retVal = parseFloat(number);
    } catch {
      return number || "$0";
    }
  } else {
    retVal = number;
  }

  return "$" + new Intl.NumberFormat("en-CA").format(retVal);
}
export function formatCurrencyShort(number) {
  let retVal = "";
  if (isNaN(number)) {
    try {
      retVal = parseFloat(number);
    } catch {
      return number || "$0";
    }
  } else {
    retVal = number;
  }

  // Define thresholds for shortening
  const shortFormatThresholds = [
    { threshold: 1e12, short: "T" }, // Trillion
    { threshold: 1e9, short: "B" }, // Billion
    { threshold: 1e6, short: "M" }, // Million
  ];

  for (const thresholdData of shortFormatThresholds) {
    if (Math.abs(retVal) >= thresholdData.threshold) {
      // If the number is greater than or equal to the threshold, use the short format
      return `$${(retVal / thresholdData.threshold).toFixed(3)}${
        thresholdData.short
      }`;
    }
  }

  // If the number doesn't meet any threshold, format it with commas
  return "$" + new Intl.NumberFormat("en-CA").format(retVal);
}
export function formatCurrencyShort2(number) {
  let retVal = "";
  if (isNaN(number)) {
    try {
      retVal = parseFloat(number);
    } catch {
      return number || "$0";
    }
  } else {
    retVal = number;
  }

  // Define thresholds for shortening
  const shortFormatThresholds = [
    { threshold: 1e12, short: "T" }, // Trillion
    { threshold: 1e9, short: "B" }, // Billion
    { threshold: 1e6, short: "M" }, // Million
  ];

  for (const thresholdData of shortFormatThresholds) {
    if (Math.abs(retVal) >= thresholdData.threshold) {
      // If the number is greater than or equal to the threshold, use the short format
      return `$${(retVal / thresholdData.threshold).toFixed(2)}${
        thresholdData.short
      }`;
    }
  }

  // If the number doesn't meet any threshold, format it with commas
  return "$" + new Intl.NumberFormat("en-CA").format(retVal);
}

export function formatNumberShort(num) {
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixIndex = 0;

  while (num >= 1000 && suffixIndex < suffixes.length - 1) {
    num /= 1000;
    suffixIndex++;
  }

  return (
    num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + suffixes[suffixIndex]
  );
}

export function formatPhone(phone) {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return +match[1] + "-" + match[2] + "-" + match[3];
  }
  return phone ?? "  -";
}
export function getBase64FromFile(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export const getRoundedAmount = ({ price }) => {
  const total = parseFloat(price);

  var multiplicator = Math.pow(10, 2);
  const n = parseFloat((total * multiplicator).toFixed(11));
  var test = Math.round(n) / multiplicator;
  return parseFloat(+test.toFixed(2));
};

export const getTotalPrice = ({ count, price }) => {
  const total = parseFloat(parseFloat(count) * parseFloat(price));

  var multiplicator = Math.pow(10, 2);
  const n = parseFloat((total * multiplicator).toFixed(11));
  var test = Math.round(n) / multiplicator;
  return +test.toFixed(2);
};

export function downloadPDF({ pdf, fileName }) {
  try {
    const linkSource = `data:application/pdf;base64,${pdf}`;

    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  } catch {}
}

export const converttoYYYYMMDD = (str) => {
  const date = new Date(str);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), month, day].join("-");
};

export const roundtwoSignificantDigits = (total) => {
  if (!total) {
    return 0;
  }
  var multiplicator = Math.pow(10, 2);
  const n = parseFloat((total * multiplicator).toFixed(11));
  var test = Math.round(n) / multiplicator;
  return +test.toFixed(2);
};
export function getFormattedDate(dateString) {
  if (!dateString) return "";

  return new Date(dateString).toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
export function formatPrice(price) {
  const formattedNumberWithOptions = price.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formattedNumberWithOptions;
}

export function validateMoneyTextBox(priceString) {
  if (!priceString) return true;

  if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(priceString)) {
    return true;
  }
  return false;
}

export function formatTime(time) {
  // Check if pickupTime is a valid date object
  if (time instanceof Date && !isNaN(time.getTime())) {
    // Get hours, minutes, and seconds from pickupTime
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    // Determine AM or PM
    const ampm = hours >= 12 ? "pm" : "am";

    // Format hours to 12-hour format
    const formattedHours = hours % 12 || 12;

    // Format minutes and seconds with leading zeros
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    // Create the formatted time string
    const formattedTime = ` ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

    return formattedTime;
  } else {
    // Handle invalid pickupTime (if needed)
    console.error("Invalid pickupTime");
    return null; // Return null or an error message as needed
  }
}
export const MAX_CHAR_LIMIT = 150;
export const MAX_CHAR_LIMIT_NAME = 50;
export const MAX_CHAR_LIMIT_CITY = 50;

export function formatUSPhone(phone) {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "- " + match[2] + "-" + match[3];
  }
  return phone ?? "";
}
