import { CacheProvider } from "@emotion/react";
import React from "react";
import { useRoutes } from "react-router-dom";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import useTheme from "./hooks/useTheme";
import routes from "./routes";
import createTheme from "./theme";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/AuthContext";

const clientSideEmotionCache = createEmotionCache();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiThemeProvider theme={createTheme(theme)}>
          <AuthProvider>{content}</AuthProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
}

export default App;
