import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import Loader from "../Loader";

// For routes that can only be accessed by guest users
function AuthGuard({ children }) {
  const navigate = useNavigate();
  const { auth, initializing } = useAuth();

  useEffect(() => {
    if (!initializing) {
      if (!auth.userId) {
        navigate("/");
      }
    }
  }, [initializing, auth.userId]);

  if (initializing) {
    return (
      <React.Fragment>
        <Loader />
      </React.Fragment>
    );
  }

  if (!auth.userId) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
