import React from "react";

import useAuth from "../../hooks/useAuth";
import Page404 from "../../pages/auth/Page404";

// For routes that can only be accessed by guest users
function PageGuard({ children }) {
  console.log({ children }, "childern");
  const { auth } = useAuth();

  let pageNumber = "";
  if (Array.isArray(children)) {
    pageNumber = children[0].props?.pageNumber;
  } else {
    pageNumber = children.props?.pageNumber;
  }

  const pageActionNumber = auth.pageActionNumber;
  const pageActionNumberFormat = pageActionNumber.map(
    (x) => x.substring(0, 8) //for page id if child else module id
  );

  if (!pageNumber) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (!pageActionNumberFormat.includes(pageNumber)) {
    return <Page404 />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default PageGuard;
