import styled from "@emotion/styled";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Alert as MuiAlert,
  TextField as MuiTextField,
  Paper,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxios";
import { AUTH_SERVICE, COMMUNICATION_SERVICE } from "../../services/constants";
const Alert = styled(MuiAlert)(spacing);
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const TextField = styled(MuiTextField)(spacing);

function ForgotPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const { id } = useParams();
  const [userId, setUserId] = useState("");
  useEffect(() => {
    const url = COMMUNICATION_SERVICE + "VerifyUUID?UUId=" + id;

    axios
      .get(url)
      .then((output) => {
        setUserId(output.data.userId);
      })
      .catch(() => {
        setLoading(false);
      });
  });
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newPasswordErrText, setNewPasswordErrText] = useState(
    "Please enter a new password"
  );
  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);

    const saveValue = event.target.value ?? "";

    setNewPasswordError(true);
    if (saveValue.length < 5) {
      setNewPasswordErrText("Password must be at least 5 characters.");
      return;
    }

    if (!/[A-Z]/.test(saveValue)) {
      setNewPasswordErrText(
        "Password must contain lowercase, uppercase, numbers and atleast one special character"
      );
      return;
    }

    if (!/[a-z]/.test(saveValue)) {
      setNewPasswordErrText(
        "Password must contain lowercase, uppercase, numbers and atleast one special character"
      );
      return;
    }

    if (!/[0-9]/.test(saveValue)) {
      setNewPasswordErrText(
        "Password must contain lowercase, uppercase, numbers and atleast one special character"
      );
      return;
    }

    if (!/[!@#\$%\^\&*\)\(+=._-]/.test(saveValue)) {
      setNewPasswordErrText(
        "Password must contain lowercase, uppercase, numbers and atleast one special character"
      );
      return;
    }

    setNewPasswordError(false);
  };
  const [fiveLengthErr, setFiveLengthErr] = useState(false);

  const [ruleErr, setRuleErr] = useState(false);

  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError(false);
  };
  const onUpdatePassword = () => {
    if (!newPassword) {
      setFiveLengthErr(true);
      setRuleErr(true);
      return;
    }

    if (fiveLengthErr || ruleErr) {
      return;
    }

    if (newPassword != confirmPassword) {
      setCheckPassword(true);
      return;
    } else {
      setCheckPassword(false);
    }

    setLoading(true);
    const url = AUTH_SERVICE + "ForgetPassword";
    let body = {
      password: newPassword,
      confirmPassword: confirmPassword,
      id: userId,
    };

    axios
      .post(url, body)
      .then((output) => {
        console.log(output, "output");
        if (output.data === true) {
          setPasswordResetSuccess(true);
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {});
  return (
    <React.Fragment>
      <Wrapper>
        <Grid container justifyContent="center" mb={7}>
          <img src="/static/img/logo.png" alt="" style={{ width: "76%" }}></img>
        </Grid>
        {!passwordResetSuccess ? (
          <>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Reset Password
            </Typography>

            <Grid container direction="row" mb={1}>
              <Grid item xs={12}>
                <TextField
                  type={showPassword ? "text" : "password"}
                  name="newpassword"
                  label="New Password"
                  value={newPassword}
                  fullWidth
                  variant="outlined"
                  error={newPasswordError}
                  helperText={newPasswordError && newPasswordErrText}
                  onChange={handleNewPassword}
                  my={2}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={showPassword2 ? "text" : "password"}
                  name="confirmpassword"
                  label="Confirm Password"
                  value={confirmPassword}
                  fullWidth
                  variant="outlined"
                  error={
                    (Boolean(
                      confirmPasswordError &&
                        "Please enter the confirm password"
                    ),
                    Boolean(
                      checkPassword &&
                        "new passowrd and confrm password doesn't match"
                    ))
                  }
                  helperText={
                    (confirmPasswordError &&
                      "Please enter the confirm password",
                    checkPassword &&
                      "Your new password and confirm password does not match")
                  }
                  onChange={handleConfirmPassword}
                  my={2}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword2(!showPassword2)}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color={"primary"}
              onClick={onUpdatePassword}
            >
              Reset Password
            </Button>
          </>
        ) : (
          <>
            <Grid container mt={4}>
              <Grid item xs={12}>
                <Alert mt={4} mb={1} severity="success">
                  Password had been updated successfully.
                </Alert>
              </Grid>
            </Grid>
          </>
        )}
        {/* <Button
          // disabled={isSubmitting}
          component={Link}
          to="/"
          fullWidth
          color="secondary"
        >
          Continue as guest?
        </Button> */}
      </Wrapper>
    </React.Fragment>
  );
}

export default ForgotPassword;
