import styled from "@emotion/styled";
import { Formik } from "formik";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useAxiosPrivate from "../../hooks/useAxios";

import {
  Button,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { COMMUNICATION_SERVICE } from "../../services/constants";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword({ setSuccess }) {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  function passwordReset(email) {
    const url = COMMUNICATION_SERVICE + "ChangePasswordRequest?email=" + email;

    return url;
  }
  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        axios
          .get(passwordReset(values.email))
          .then((output) => {
            if (output.data.isSuccess) {
              setSuccess(true);
            } else {
              const message = output.data.error || "Invalid Email";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          })
          .catch((err) => {
            const message = "Something went wrong";
            console.log("inside catch");

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            mt={4}
          >
            Reset password
          </Button>
          <Button component={Link} to="/" fullWidth color="secondary">
            Sign In Instead
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
