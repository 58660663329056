import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [initializing, setInitializing] = useState(true);
  const [logOUtPopUP, setLogOutPopUP] = useState(false);
  const [isQuoteEditing, setIsQuoteEditing] = useState(false);
  const [quoteEditSaveStepperPopUp, setQuoteEditSaveStepperPopUp] =
    useState(false);
  const [quoteEditSaveMenuPopUP, setQuoteEditSaveMenuPopUP] = useState(false);
  const [menuPopUpNavigate, setMenuPopUpNavigate] = useState("");
  useEffect(() => {
    try {
      const authLS = window.localStorage.getItem("auth");
      if (authLS) {
        const saveAuth = JSON.parse(authLS);
        setAuth(saveAuth);
      }
    } catch {
      //do nothing
    } finally {
      setInitializing(false);
    }
  }, []);
  useEffect(() => {
    // Define the event listener function
    const handlePopState = (event) => {
      setIsQuoteEditing(false);
      /* eslint-disable */
    };

    // Add the event listener
    window.addEventListener("popstate", handlePopState);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isQuoteEditing]);

  const logOut = () => {
    localStorage.clear();
    setAuth({
      roles: null,
      refreshToken: null,
      userId: null,
      userName: null,
      pageActionNumber: null,
      image: null,
      team: null,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        initializing,
        logOut,
        setLogOutPopUP,
        logOUtPopUP,
        isQuoteEditing,
        setIsQuoteEditing,
        quoteEditSaveStepperPopUp,
        setQuoteEditSaveStepperPopUp,
        quoteEditSaveMenuPopUP,
        setQuoteEditSaveMenuPopUP,
        menuPopUpNavigate,
        setMenuPopUpNavigate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
