import styled from "@emotion/styled";
import { Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  IconButton,
  InputAdornment,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { axiosBase } from "../../services";
import { AUTH_SERVICE } from "../../services/constants";
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const { setAuth } = useAuth();
  const LOGIN_URL = AUTH_SERVICE + "AuthenticateUser";
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const response = await axiosBase.post(
            LOGIN_URL,
            JSON.stringify({ email: values.email, password: values.password })
          );

          if (response.data.error) {
            setErrors({ submit: response.data.error });
            return;
          }

          const userId = response?.data?.id;
          const userName = response?.data?.name;
          const refreshToken = response?.data?.token;
          const image = response?.data?.image;
          const team = response?.data?.team;
          const roles = response?.data?.roles;
          const pageActionNumber = response?.data?.canAccessPageActions;
          // const pageActionNumber = ["000300020005"];
          setAuth({
            roles,
            refreshToken,
            userId,
            userName,
            pageActionNumber,
            image,
            team,
          });
          localStorage.setItem(
            "auth",
            JSON.stringify({
              roles,
              refreshToken,
              userId,
              userName,
              pageActionNumber,
              image,
              team,
            })
          );

          //
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign In
          </Button>
          <Button
            component={Link}
            to="/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
