import styled from "@emotion/styled";
import React from "react";

import { Box, ListItemButton, Drawer as MuiDrawer } from "@mui/material";

import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: white;
  background-color: white;
  font-family: ${(props) => props.theme.typography.fontFamily};

  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      {/* <Brand component={NavLink} to="/"> */}
      <Brand>
        <Box ml={1} sx={{ backgroundColor: "white", textAlign: "center" }}>
          {/* Mira <BrandChip label="PRO" /> */}
          <img src="/static/img/logo.png" style={{ width: "76%" }}></img>
        </Box>
      </Brand>
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
